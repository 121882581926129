/********************************************************************************************************************************
Reconfiguration des prédéfinis
********************************************************************************************************************************/ 
html, body {
  margin:0px;
}
input, select {
  background-Color:white;
  border-Width: 0.5px;
  border-Color:#C5C5CC;
  border-Radius: 10px;
  margin-Bottom:50px;
  margin-Top: 10px;
  margin-Left:5px;
  color: #616161;
  width: 97%;
  height: 70px;
  padding-left: 20px;
  font-size: 175%;
}
input[type="range"] {
  padding: 0;
}
input:disabled{
  color: white;
  background-color: #bebbbb;
}
select {
  margin-bottom: 150px;
}
h1 {
  font-size: 40px;
}
li {
  color:#92D050
} 
li .lila {
  color :black;
  margin-top: 1%;
}
li .lila2{
  margin-top: 0px;
}
button {
  cursor: pointer;
}
a {
  text-decoration: none;
  color: inherit;
}
a.a_corps_SW {
  color: #92D050;
  text-decoration: underline;
}
/********************************************************************************************************************************
Global
********************************************************************************************************************************/ 
.container{
  margin:20px;
  margin-Top: 130px;
  margin-bottom: 150px;
  height: 100%;
}
.containerSW {
  margin: 20px;
  text-align: justify;
  font-size: 13px;
  padding-top: 60px;
}
.titre {
  font-weight: bold;
  margin-top: 20px;
  font-size: 40px;
}
.titrePlus {
  font-weight: bold;
  margin-top: 20px;
  font-size: 45px;
}
.titreMoins {
  font-weight: bold;
  margin-top: 20px;
  font-size: 35px;
}
.titreMoinsMoins {
  font-weight: 500;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 32px;
  color: #7c7c7c;
}
.lighter {
  font-weight: 600;
  font-size: 38px;
}
.paragraphe {
  font-size: 30px;
}
.paragrapheSmaller{
  font-size: 25px;
}
.paragrapheSmaller{
  font-size: 25px;
}
.paragrapheAleas {
  font-size: 16px;
  font-weight: 500;
}
.card {
  margin-top:30px;
  margin-bottom:30px;
  margin-right: 0px;
  width: 95%;
  border-Radius: 10px;
  padding:10px
}
.titreCard {
  font-weight: bold;
  margin-top: 5px;
  font-size: 26px;
}
.row {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  align-items: center;
  justify-content: center;
}
.column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/********************************************************************************************************************************
Entête OAD et site web 
********************************************************************************************************************************/ 
.entete{
  height:110px;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  position: fixed;
  background-color: #dfe9df;
  top: 0;
  left: 0;
  z-index: 2;
}
.enteteSW{
  height: 80px;
  background-color: #e7e7e7;
}
.LogoRond {
  margin-right: 20px;
  height: 70%;
}
.ButtonHome {
  border: 0px;
  height: 80%;
  margin-left: 10px;
  background-color: transparent;
}

.disableMD {
  background-color: #e7e7e7;
  border: solid 0.5px#b0b0b0;
  color: #b0b0b0;
}
.IconHome{
  height: 90%;
}
.IconeCam{
  height: 70%;
}
.IconHome:hover {
  opacity: 0.65;
}
.encartProfil{
  width: 30%;
  padding: 5px;
  position: fixed;
  top: 110px;
  right: 0;
  border: solid #a1a1a1 0.5px;
  z-index: 30;
}

/********************************************************************************************************************************
Menu OAD
********************************************************************************************************************************/ 
.menuOad {
  height:135px;
  width: 100%;
  justify-content: space-between;
  background-color: #92D050;
  position:fixed;
  margin-bottom: 0px;
  left: 0;
  bottom:0;
  z-index: 15;
}
.SOmenuOad {
  width: 35%;
  justify-content: space-around;
  left: 65%;
  bottom: 135px;
}
.ButtonMenuOAD{
  width: 20%;
  height: 100%;
  border: 0px;
  background-color: transparent;
}
.IconMenuOAD {
  height: 65%;
  margin-top: 5px;
}
.IconMenuOADDefault {
  filter: grayscale(1)
}
.IconMenuOADChecked {
  filter: grayscale(0)
}
.IconMenuOAD:hover {
  opacity: 0.65;
}
.nomMenuOAD {
  font-size: 22px;
  font-weight: 600;
}
.nomMenuOADDefault {
  Color: #ffffff;
}
.nomMenuOADChecked {
  Color: #1A213F;
}

/********************************************************************************************************************************
Pied de page
********************************************************************************************************************************/ 
.footer {
  width: 100%;
  background-color: #1A213F;
  padding-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: stretch;
}
.footer_gauche {
  width: 30%;
  padding-left: 3%;
}
.footer_gauche_centre {
  display: none
}
.footer_centre {
  color: white;
  text-align: center;
  width: 30%;
}
.footer_droit {
  width: 29%;
  padding-right: 3%;
  padding-left: 5%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.contactLeft {
  justify-content: left;
}
.rowFooter {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
}
.logo_BV {
  width: 50%;
  aspect-ratio: 25 / 16;
}
.logo_BV_Footer {
  width: 50%;
  aspect-ratio: 60 / 16;
}
.logo_pin_Footer {
  width: 20%;
}
.logo_feuille_Footer{
  width: 40%;
}
.logo_perqo_Footer {
  width: 95%;
}
.buttonSiteFooter{
  color: #92D050;
  background-color: transparent;
  text-shadow: none;
  box-shadow: none;
  border:none;
  text-align: start;
}
.buttonSiteFooter:disabled{
  color: white;
}
.logo_contact {
  width: 20%;
  margin-left: 7px;
  margin-right: 7px;
}
.logo_LI {
  width: 100%;
}
.copyright{
  background-color: white;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 120px;
}
.buttonFooter {
  font-size: 25px;
  color: inherit;
  height: auto;
  text-decoration: underline;
  margin-top: 7px;
  margin-right: 10px;
}

/********************************************************************************************************************************
Navbar burger du site web
********************************************************************************************************************************/ 
.navbarSW {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: fixed;
  width: 100%;
  min-height: 30px;
  background-color:transparent;
  z-index: 25;
}
.navbarSW_item::after {
  content: '';
  display: block;
  margin: 0 auto;
  width: 3vw;
  height: 1px;
  background-color: black;
}
.navbarSW_item:last-child::after {
  display: none;
}
.navbarSW_burger {
  width:60px;
  height: 50px;
  margin-left: 20px;
  background-color: #e7e7e775;
  border: none;
  color: black;
  padding-left: 5px;
  padding-right: 5px;
  cursor: pointer;
  position: fixed;
  top: 0.7rem;
  left: 0.4rem;
}
.burgerOAD {
  width: 100px;
  height: 100px;
  margin-left: 5px;
  top: 0.5rem;
}
.navbarSW_links {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  right: -100vw;
  bottom: 0px;
  height: 100%;
  background-color: white;
}
.linksOAD{
  background-color: #DFE9DF;
}
.backgroundAdmin {
  background-color: #F2DE27;
}
.backgroundExploitant {
  background-color:  #92D050;
}
.backgroundFlash{
  background-color:  #00D287;
}
.show-nav .navbarSW_links {
  width: 100vw;
  right:0px;
}
.navbarSW_lien {
  padding: 0 0.3rem;
  color: black;
  display: block;
  padding: 1.5rem;
  font-size: 5vw;
  height: unset;
  margin-right: unset;
}
.navbarSW_lien:disabled {
  color: #929292;
}
.navbarSW_lienAdmin:disabled {
  color: #929292;
}
.navbarSW_lienExploit:disabled {
  color: #ffffff;
}
.burger-bar, 
.burger-bar::before, 
.burger-bar::after {
  display: block;
  width: 50px;
  height: 3px;
  position: relative;
  border-radius: 3px;
  background-color: black;
}
.burger-bar::before, 
.burger-bar::after {
  content:'';
  position: absolute;
  left: 0px;
}
.burger-bar::before {
  transform: translateY(-12px);
}
.burger-bar::after {
  transform: translateY(12px);
}
.show-nav .burger-bar {
  background-color: transparent;
}
.show-nav .burger-bar::before {
  transform: rotate(45deg);
}
.show-nav .burger-bar::after {
  transform: rotate(-45deg);
}
ul.navbarSW_links {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

/********************************************************************************************************************************
Navbar site web
********************************************************************************************************************************/ 
.navSW{
  width: 100%;
  position: fixed;
  top: 0;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #9AE748;
  z-index: 25;
}
.liste{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  flex-direction: column;
  list-style: none;
  background-color:#9AE748;
  z-index: -1;
}
.liste2{
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: flex-start;
  font-weight: bold;
  list-style: none;
  background-color:#9AE748;
  z-index: -1;
  margin: 0px;
}
.ButtonEnteteNav{
  border: none;
  background-color: transparent;
  font-size: 35px;
  color: black;
}
.buttonRightEnteteNav{
  font-size: 25px;
}
.buttonConnexionNav{
  background-color:white;
  height: 35px; 
  margin-left:5px;
  box-Shadow: 5px 5px 5px #C4C4C4;
}
.buttonConnexionNav:hover{
  background-color:#f2ffe4;
  height: 35px; 
  margin-left:5px;
}
.item{
  font-weight: bold;
  margin: 1rem;
  color: black;
  cursor: pointer;
  transition: 1s ease-in-out;
}
.item:hover{
  color: #4c4c4c;
}
.textNavAccueil{
  display: inline;
}
.logoNavAccueil{
  display: none;
}
.navSW_burger{
  display: block;
  height: 50px;
  background-color: transparent;
  margin-left: 1rem;
  border: none;
  color: black;
  padding-left: 5px;
  padding-right: 5px;
  cursor: pointer;
  position: fixed;
  top: 0.4rem;
  left: 0.4rem;
}
.show-nav .liste {
  display: flex;
}
.hide-nav .liste{
  display: none;
}

/********************************************************************************************************************************
Not found
********************************************************************************************************************************/ 
.containerNotFound{
  margin-top: 40%;
}
.mascotte_notfound{
  width: 70%;
  aspect-ratio: 4/4;
}
.titreNotFound{
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 10px;
}
.paragrapheNotFound {
  font-size: 26px;
  margin-bottom: 10px;
}
.buttonNotFound {
  border: 0px;
  height: 80%;
  color: #92D050;
  background-color: transparent;
}

/********************************************************************************************************************************
Acceuil
********************************************************************************************************************************/ 
.accueil {
  width: 100%;
  height: 100%;
  padding-top: 70px;
}
.PremierBlocAccueil{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  background-image: url("./assets/Vigne-grandes-culture-accueil4.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position:relative;
  width: 100%;
}
.imageEnteteAccueil{
  width: 100%;
}
.video_prez_DR{
  width: 80%;
  aspect-ratio: 16/9;
  margin-bottom: 10px;
}
.propositionValeur{
  margin-top: 1.5%;
  margin-bottom: 1%;
  color: #1A213F;
  text-align: center;
  font-size: 32px;
}
.peintureDR{
  position:relative;
  width: 80%;
  margin-top: 15px;
  height: 100%;
  padding-left: 3%;
  padding-right: 3%;
  padding-top: 2%;
  padding-bottom: 2%;
  text-align: center;
  /* border-radius: 40px;
  border: solid 4px #9AE748;; */
}
.paragrapheAccueil{
  font-size: 35px;
}
.paragrapheLanding{
  font-size: 29px;
}
.compositIMGAccueil{
  width: 10%;
  object-fit: cover
}
.IconMenuAccueil {
  width: 100%;
  aspect-ratio: 15/ 12;
}
.buttonMenuAccueil {
  padding-left: 10px;
  padding-Top: 10px;
  border-Width: 0px;
  width: 8%;
  background-color: rgba(255, 255, 255, 0);
}
.App-logo {
  width: 45%;
  aspect-ratio: 25 / 16;
}
.App-logoMenuSW {
  width: 20%;
  aspect-ratio: 25 / 16;
}
.Mans-logo {
  width: 25%;
  aspect-ratio: 100 / 35
}
.principal {
  height:100%;
  width:100%;
  display: flex;
  flex-Direction:column;
  justify-content : space-between;
}
.principal2 {
  margin-top: 150px;
}
.top1 {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}
.top2 {
  margin-Top: 20px;
  margin-Bottom: 40px;
  display: flex;
  flex-Direction: column;
  align-Items: center ;
}
.bottom {
  margin-Top: 50px;
  margin-bottom: 50px;
  display: flex;
  flex-Direction: column;
  align-Items: center;
}
.bottom2 {
  align-items: flex-start;
  width:95%;
}
.buttonAccueil {
  border-Width: 0.5px;
  border-Radius: 10px;
  margin-Bottom: 3%;
  align-Items: center;
  padding: 15px;
  width:75% ;
  height: 100px;
  font-Size: 30px;
  margin-left: 20px;
  margin-right: 20px;
}
.buttonAccueil:hover {
  background-color: rgba(255, 255, 255, 0.8);
}
.validation {
  background-Color: #92D050;
  box-Sizing: border-box;
  border-Radius: 10px;
  border-Width: 0.5px;
  padding: 10px;
  border-color: #616161;
  color: #616161;
  width: 70%;
  margin-Left:20px;
  margin-Top:20px;
  font-size: 23px;
}
.validation:hover {
  background-color: #a7d378;
}
.validation:active {
  box-shadow: inset -2px -2px 3px rgba(255, 255, 255, .6),
              inset 2px 2px 3px rgba(0, 0, 0, .6);
}
.validationCompSimul{
  width: 50%;
  background-Color: #c0e698;
  font-size: 22px;
  margin-top: 30px;
}
.BCompSimulActive{
  background-color: #F2DE27;
  border: solid 1px grey;
}
.BCompSimulDesActive{
  background-color: white;
  border: solid 2px #F2DE27;
}
.BCompSimulActive:hover{
  background-color: #faef8b;
}
.BCompSimulDesActive:hover{
  background-color: #faef8b;
}
.rowCompSimul{
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: normal;
}
.buttonInactive {
  background-Color: #D0D0D0;
  border-Color:#616161;
  color: #616161;
}
.buttonActive:active {
  box-shadow: inset -2px -2px 3px rgba(255, 255, 255, .6),
              inset 2px 2px 3px rgba(0, 0, 0, .6);
}
.buttonActive {
  background-Color: white;
  border-Color:#24243E;
  color: black;
}
.buttonActive:active {
  box-shadow: inset -2px -2px 3px rgba(255, 255, 255, .6),
              inset 2px 2px 3px rgba(0, 0, 0, .6);
}
.titreAccueil {
  width: 75%;
  text-align: center;
  font-size: 33px;
  font-weight: 500;
  margin-top: 30px;
  margin-bottom: 10px;
}
.cardAccueil {
  width: 100%;
  margin-left: 5%;
  margin-right: 5%;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-Content: center;
  align-items: stretch;
}
.cardAccueil2 {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.cardAccueil3 {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.imgcardaccueil2{
  width: 90%;
  border-radius: 40px;
}
.prghduoaccueil{
  width: 90%;
  text-align: justify;
}
.imgduoaccuel{
  border: solid 1px #2e3866;
}
.cardDRAccueil{
  background-color: #E5FFC9;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}
.cardDRTextAccueil{
  padding: 2%;
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.textCardAccueil {
  font-size: 27px;
  width: 70%;
}
.textCardAccueil2 {
  font-size: 23px;
  width: 65%;
  margin-right: 10px;
}
.textCardAccueil3 {
  width: 100%;
  margin-right: 0px;
}
.partenaireDemicercle{
  width: 100%; /* largeur du carré */
  height: 100%; /* hauteur du carré */
}
.imgPartenaire{
  width: 15%;
  margin-left: 2%;
  margin-right: 2%;
}
.titreBleuAccueil{
  text-align: center;
  font-weight: bold;
  width: 80%;
  font-size: 24px;
}
.validationAccueil{
  border: none;
  width: 50%;
  color: #292929;
}
.validationAccueil2{
  border: none;
  width: 50%;
  color: #292929;
  background-color: #9AE748;
}
.titreImage1Accueil{
  font-size: 30px;
}
.titreImage2Accueil{
  color: white;
  width: 20%;
  margin: 10px;
}
.imageAccueil {
  width: 25%;
  border-radius: 6px
}
.starAccueil{
  opacity: 0.7;
}
.textboxHome{
  width:40%;
  margin-left: 20PX;
}
.videoHome{
  margin-top: 20px; 
  margin-bottom: 20px;
  width:50%;
  height:315px
}

/********************************************************************************************************************************
Inscription
********************************************************************************************************************************/
.textInscription {
  font-size: 30px;
  width: 90%;
}
.checkInscription{
  width: 10%;
  height: 40px;
}
.inscriptionVal {
  text-align: center; 
  margin-Top: 900px;
  font-Size: 40px;
}

.inputCodeVal {
  width: 150px;
  height: 60px;
  font-size: 90%;
}
.validation:disabled{
  background-color: #9c9c9c;
  color: #ffffff;
}

/********************************************************************************************************************************
Connexion
********************************************************************************************************************************/ 
.PasseWord {
  width: 100%;
  justify-content: left;
}
.ButtonShowHide {
  position: absolute;
  right: 95px;
  top: 1130px;
  border: 0px;
  margin-left: -60px;
  height: 30px;
  background-color: transparent;
}
.ButtonShowHideConnexion{
  right: 95px;
  top: 810px;
}
.ButtonShowHide2 {
  right: 50px;
  top: 80px;
}
.ButtonShowHide3 {
  right: 50px;
  top: 200px;
}

/********************************************************************************************************************************
Assolement
********************************************************************************************************************************/
.LogoPin {
  height: 45px;
  margin-right: 10px;
}
.checkAssol{
  width: 45px;
  height: 25px;
  margin-top: 0px;
  margin-bottom: 0px;
}
.ligne_assol {
  justify-content: flex-start;
  padding-bottom: 5px;
  padding-top: 5px;
  margin-bottom: 0px;
  border-bottom-style: solid;
  border-bottom-width: 0.5px;
  border-bottom-color: rgb(170, 170, 170);
  margin-left: 30px;
  margin-right: 30px;
}
.masquer_en_portrait{
  display: none;
}
.col_assol {
  font-size: 32px;
  padding: 5px;
}
.col_libelle {
  width: 70%;
}
.col_libelle_entete {
  width: 70%;
  display: flex;
}
.col_libelle_cult_plus {
  width: 70%;
}
.col_suppr_cult_plus {
  display: none;
}
.col_TotFinal{
  width: 70%;
}
.col_spe{
  display: none;
}
.col_surface {
  width: 30%;
  display: flex;
  justify-content: flex-end;
}
.col_semis{
  display: none;
}
.showButtonSemisTrue .showButtonSemisFalse{
  display:none
}
.col_buttonSemis{
  display: none;
}
.col_rdt {
  display: none;
}
.col_prix {
  display: none;
}
.col_PB_unitaire {
  display: none;
}
.col_PB_tit_Prod{
  display: none;
}
.col_PB_tot {
  display: none;
}
.disableVitiInput{
  background-color: rgb(233,233,233);
}
.pleinBlocAssol {
  border-Radius: 6px;
  background-color: #C5C5CC;
  display: flex;
  justify-Content:center;
  align-Items: center;
  height:100%;
  width:30%;
  margin-left: 20px;
}
.videBlocAssol {
  border: solid #C5C5CC;
  border-radius: 10px;
  border-Width: 2px;
  width:70%;
  height:70px;
  display: flex;
  flex-direction: row;
  align-Items:center;
  justify-content: flex-end;
  margin-top: 5px;
  margin-bottom: 5px;
}
.input_assol {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  color: black;
  height: 65px;
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 30px;
  text-align: center;
  border-width:0px ;
  padding: 0px;
}
.input_assol_semis{
  border-width: 1px;
  height: 40px;
  margin: 0px;
}
.input_assol:focus, .input_assol:focus-within {
  outline: none;
}
.input_assol:disabled{
  background-color: white;
  color: #b0b0b0;
}
.inputNomSimul{
  font-size: 90%;
  width: 400px;
  height: 40px;
  margin-left: 20px;
}
.input_radio{
  width: 15px;
  height: 15px;
  margin-bottom: 5px;
  margin-left: 10px;
  accent-color: #7DA455;
}


/********************************************************************************************************************************
Assolement
********************************************************************************************************************************/
.ButtonGestionUtilisateur{
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0px;
  background-color: transparent;
  border-radius: 5px;
}
.BGUGreen{
  background-color: #92D050;
}
.ag-header-group-cell-label, .ag-header-cell-label {
  justify-content: center;
}
.ag-ltr .ag-cell {
  display: flex;
  align-items: center;
  line-height: 1.5em;
  padding-top: 10px;
  padding-bottom: 10px;
}
.ag-theme-alpine .ag-filter-toolpanel-header, .ag-theme-alpine .ag-filter-toolpanel-search, .ag-theme-alpine .ag-status-bar, .ag-theme-alpine .ag-header-row, .ag-theme-alpine .ag-panel-title-bar-title, .ag-theme-alpine .ag-multi-filter-group-title-bar, .ag-theme-alpine-dark .ag-filter-toolpanel-header, .ag-theme-alpine-dark .ag-filter-toolpanel-search, .ag-theme-alpine-dark .ag-status-bar, .ag-theme-alpine-dark .ag-header-row, .ag-theme-alpine-dark .ag-panel-title-bar-title, .ag-theme-alpine-dark .ag-multi-filter-group-title-bar{
  height: 35px;
}

/********************************************************************************************************************************
Page site web : Solutions
********************************************************************************************************************************/ 
.App-logo_solution {
  width: 45%;
}
.firstLigneSolG{
  width: 100%;
}
.firstLigneSolD{
  width: 100%;
  margin-left: 0px;
  margin-top: 20px;
}
.rowSolutions{
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.column_sol{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.Sol_p{
  font-size: 25px;
  margin-bottom: 30px;
  margin-left: 0px;
  margin-right: 0px;
}
.Sol_icon{
  width: 40%;
}
.Sol_logoS{
  width: 70%;
  margin-bottom: 60px;
}
.devices_rdt{
  width: 70%;
  display: flex;
  order: 1;
}
.ligneLogoSol{
  width: 100%;
  display: flex;
  flex-direction: column;
  order: 2;
}
.degre_Solution2050{
  font-size: 15px;
}

/********************************************************************************************************************************
Page site web : Blog
********************************************************************************************************************************/ 
.titreSW{
  margin-left: 0%;
  margin-right: 0%;
  margin-top: 0%;
  font-size: 40px;
}
.blogContainer{
  width: 85%;
  border: solid 2px #92D050;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px;
  margin-left: 7%;
}
.titreBlog{
  font-size: 30px;
  text-align: center;
  width: 100%;
  color: #92D050;
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 0px;
}
.texteGauche{
  width: 100%;
  padding-right: 10px;
}
.Pgauche{
  margin-bottom: 10px;
}
.imageDroite {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.rowBlog{
  display: flex;
  justify-content: center;
  align-items: center;
}
.rowBlog1{
  flex-direction: column;
}
.rowBlog2{
  flex-direction: column-reverse;
}
.dateBlog{
  width: 100%;
  text-align: start;
  color: #92D050;
  font-size: larger;
  font-weight: bold;
}
.imageBlog {
  width:80%;
  aspect-ratio: 1;
  object-fit: cover;
}

/********************************************************************************************************************************
Page site web : Abonnement / Projets
********************************************************************************************************************************/ 
.tableauAbo{
  margin-left: 2%;
  margin-right: 2%;
  margin-top: 125px;
  margin-bottom: 50px;
}
.LogoOffres {
  width: 50%;
  aspect-ratio: 1
}
.LogoOuiNon{
  margin-top: 10px;
  margin-bottom: 10px;
  width: 40%;
}
.enteteLigneAbo{
  width: 52%;
  height: 200px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
}
.celluleInfoAbo{
  width: 16%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px;
}
.celluleTitreAbo{
  height: 200px;
  width: 16%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: bold;
}
.ligneCorpAbo{
  border: solid rgb(196, 196, 196);
  border-width: 1px;
}
.enleveMarge{
  margin: 0px;
}
.cardAbo{
  margin-left: 10px;
  margin-right: 10px;
  border: solid ;
  border-width: 3px;
}
.cardAboPro{
  border-color: #92D050;
  color: #92D050;
}
.cardAboPremium{
  border-color: #75B532;
  color: #75B532;
}
.cardAboPremiumRecap{
  padding: 30px;
  width:90%;
  margin-top: 10px;
}
.cardAboOF{
  border-color: #4B4BB5;
  color: #4B4BB5;
  padding: 20px;
}
.cardAboAssureur{
  border-color: #37376A;
  color: #37376A;
  padding: 20px;
}
.cardAboOPA{
  border-color: #24243E;
  color: #24243E;
  padding: 20px;
}
.cardAboSurMesure{
  border-color: #4F7D1D;
  color: #4F7D1D;
}
.LogoProCard{
  width: 15%;
  margin-right: 20px;
}
.LogoProCardRecap{
  width: 5%;
  margin-right: 20px;
}
.paraCardAbo{
  margin-bottom: 15px;
}
.validationAbo{
  border-width: 0.2px;
  border-color: #a6a6a6;
  color: white;
  margin-top: 0px;
  margin-left: 0px;
}
.selectAbo{
  height: 45px;
  border-color: #75B532;
  color: #75B532;
  margin-bottom: 15px;
}
.tarifHT{
  font-size: 32px;
  text-align: center;
  margin-bottom: 0px;
  font-weight: bold;
}
.tarifparpers{
  font-size: 27px;
  text-align: center;
  margin-bottom: 0px;
}
.rowTarifRecap{
  width:100%;
  justify-content: space-between; 
  flex-wrap:wrap;
}
.boutonRecap{
  justify-content: flex-start;
}
.tarifHTPers{
  width: 60%;
}
.boutonsRecap{
  width: 70%;
  justify-content: flex-start;
}
.cellBCPTextAbo{
  text-align: center;
  height: 200px;
}
.sous-Titre-SW{
  color: #92D050;
  width: 100%;
  text-align: center;
  font-weight: bold;
  font-size: 35px;
}
.imageprojetARRUP{
  width: 100%;
}
.texteARRUP{
  width: 100%;
  padding-left: 10px;
  padding-right: 25px;
}
.sous-sous-titre-SW{
  color: #1A213F;
  margin-top: 0px;
  font-size: 30px;
}

/********************************************************************************************************************************
Page site web : Qui sommes-nous
********************************************************************************************************************************/ 
.RiAg {
  width:50%;
  aspect-ratio:7/1;
}
.Mains {
  width:100%;
  aspect-ratio: 3/1;
  object-fit: cover;
}
.QSM_p {
  font-size: 33px;
}

/********************************************************************************************************************************
Page site web : Nous rejoindre
********************************************************************************************************************************/
.cardEmplois{
  width: 97%;
  background-color: transparent;
  border-Radius: 10px;
  border: solid 1px;
  border-color: #8b8a8a;
  padding: 10px;
  margin-bottom: 10px;
  align-items: center;
}
.textCardEmplois {
  font-size: 20px;
  width: 60%;
}
.imageCardEmplois{
  width: 100%;
}
.titrOffreEmplois{
  margin-top:0px;
  text-align: center;
  font-size: 35px
}

/********************************************************************************************************************************
Mes simulations
********************************************************************************************************************************/
.col_check{
  width: 10%;
}
.col_date {
 display: none;
}
.col_libelle_simul {
  width: 40%;
  margin-right: 30px;
}
.col_assol_culture {
  display: none;
}
.col_button {
  width: 45%;
}
.consult_simul{
  width: 30%;
  margin: 0px;
  margin-left: 1%;
}
.supp_simul{
  width: 32%;
}
.impr_simul{
  width: 38%;
  background-color: #d5d5d5;
  margin: 0px;
  margin-left: 1%;
}
.impr_simul:hover {
  background-color: #e9e8e8;
}
.checkSimulComp{
  margin: 0px;
  width: 50%;
}
.IMGbuttonCheckedSimul{
  width: 100%;
}
.buttonCheckedSimul{
  background-color: white;
  width: 100%;
  height: 80%;
  border: 0px;
}

/********************************************************************************************************************************
Questions ?
********************************************************************************************************************************/
.titreQuestion {
  border-Radius: 6px;
  display: flex;
  justify-Content: left;
  align-items: center;
  margin-top: 0px;
  height: 40px;
  width: 100%;
  background-color: #c9eba2;
  padding-top: 5px;
  padding-bottom: 5px;
  font-weight: bold;
  font-size: 28px;
 }
.videBlocQuestion {
  display: flex;
  border-Radius: 6px;
  width: 100%;
  flex-direction: column;
  justify-Content: left;
  align-items: flex-start;
  background-color: #EEF8E4;
  padding-bottom: 5px;
  margin-bottom: 20px;
}

/********************************************************************************************************************************
Page OAD : Exploitation
********************************************************************************************************************************/


/********************************************************************************************************************************
Page OAD : Risques rendement
********************************************************************************************************************************/
.axeX {
  justify-Content:space-between;
  margin-top: -20px;
}
.textX {
  width: 30%;
  font-size: 27px;
  text-Align: center
}
.marge {
  margin-Top:10px;
  margin-bottom: 10px;
}
.titreBloc {
  color:white;
  font-Size: 26px;
}
.paragrapheBloc {
  font-Size: 26px;
  font-Weight:bold;
  margin-Left:26px
}
.paragrapheBloc7 {
  color:#FFC000;
}
.paragrapheBloc20 {
  color:#0027CC;
}
.paragrapheBloc50 {
  color:#DB04B9;
}
.pleinBloc {
  border-Radius: 8px;
  padding-Left:15px;
  height:100%;
  width:40%;
  margin-bottom: 0px;
  justify-content: left;
}
text{
  padding: 10px !important ;
  font-size: 20px;
}
.videBloc {
  border: solid;
  border-Radius: 10px;
  border-Width: 2px;
  width:95%;
  height:65px;
  margin-bottom: 5px;
  justify-content: left;
}
.pleinBloc7 {
  background-Color:#FFC000;
}
.videBloc7 {
  border-Color:#FFC000;
}
.pleinBloc20 {
  background-Color:#0027CC;
}
.videBloc20 {
  border-Color:#0027CC;
}
.pleinBloc50 {
  background-Color:#DB04B9;
}
.videBloc50 {
  border-Color:#DB04B9;
}
.leaflet-container{
  height: 400px;
  z-index: 1;
}
.infos_carte {
  background-color: #E5FBCE;
  border-radius: 10px;
  display: inline-block;
  padding: 5px;
  margin-bottom: 10px;
  margin-top: 5px;
}
.buttonLegendeCarte {
  background-Color: #b5b5b5;
  font-size: 25px;
  margin-left: 30px;
  color: black;
}
.buttonCarte {
  margin-right: 10px;
}
.buttonEntite {
  height: 60px;
  font-size: 25px;
}
.popupLegend{
  position: fixed;
  top : 40%;
  left: 20%;
  width: 30%;
  height : 1px;
  background-color: #E0E0E0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
}
.popupLegend2050{
  position: fixed;
  top : 60%;
  left: 20%;
  width: 65%;
  height : 1px;
  background-color: #E0E0E0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
}
.legendCarte_RiskRDT2050 {
  width: 600px;
}
.popupConsentement{
  position: fixed;
  top : 40%;
  left: 20%;
  width: 30%;
  height : 1px;
  background-color: #E0E0E0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
}
.popupSupp{
  left: 15%;
  top: 30%;
  height: 300px;
  width: 70%;
}
.popupMDP{
  left: 10%;
  top: 30%;
  height: 400px;
  width: 80%;
}
.popupCons{
  left: 5%;
  top: 15%;
  height: 70%;
  width: 90%;
}
.popup-inner {
  padding: 10px;
  width: 100%;
  max-width: 640px;
  background-color: #E0E0E0;
}
.popup-inner-MDP {
  padding: 10px;
  width: 100%;
  background-color: #E0E0E0;
}
.popup-inner .close-btn {
  position: absolute;
  top: -250px;
  right: 16px;
  font-size: 20px;
}
.popup-inner .close-btn2050 {
  position: absolute;
  top: -240px;
  right: 8px;
  font-size: 20px;
}

.popup-inner .close-btn-profil {
  top: 10px;
}
.popup-inner-MDP .close-btn {
  position: absolute;
  top: -210px;
  right: 16px;
  font-size: 20px;
}
.popup-inner-MDP .close-btn-profil {
  top: 10px;
}
.legendCarte_RiskRDT {
  width: 200px;
}
.buttonCartoEntiteChecked {
  background-Color: #92D050;
}
#popup_carte_riskRDT {
  font-size: 30px;
}


/********************************************************************************************************************************
Page OAD : Risques prix
********************************************************************************************************************************/
.cardPrix {
  background-color: #D8ECFF;
  margin-top: 5px;
  margin-bottom: 0px;
}
.flexLignePrix {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-Content: space-evenly;
  margin-top:20px
}
.titrePrix {
  font-Size: 23px;
  text-Align: right; 
  width: 20%;
}
.active {
  font-Size: 40px;
  color: black;
}
.active2 {
  font-Size: 27px;
  color: black;
}
.legend {
  margin-bottom:15px

}
.legendBloc {
  margin-right:30px
}
.legendGraphiquePrix {
  font-Size:40px;
  font-Weight:bold;
  transform: rotate(90deg) ;
  margin-right:10px;
}
.legendGraphiquePrixBis {
  transform: rotate(0deg) ;
}
.legendcharges {
  color:#FFA366;
}
.legendventes {
  color:#3075FF;
}
.blocPrix {
  margin-right:5px; 
  margin-bottom: 20px;
  width:150px;
}
.paragrapheBlocPrix {
  font-Size: 30px;
  font-Weight:bold;
  margin-top: 20px;
}
.paragrapheBlocPrix2 {
  font-Size: 25px;
  text-align: center;
}
.pleinBlocPrix {
  border-Radius: 6px;
  display: flex;
  justify-Content:center;
  align-Items: center;
  margin-top: -2px;
  height:40%;
  width:100%
}
.videBlocPrix {
  border: solid;
  border-radius: 10px;
  display: flex;
  border-Width: 2px;
  width:90%;
  flex-direction: column;
  height:150px;
  align-Items:center;
}
.paragrapheBloc3 {
  color:#82E2F3
}
.pleinBloc3 {
  background-Color:#82E2F3;
}
.videBloc3 {
  border-Color:#82E2F3;
}
.paragrapheBloc6 {
  color:#1FBBED;
}
.pleinBloc6 {
  background-Color:#1FBBED;
}
.videBloc6 {
  border-Color:#1FBBED;
}
.paragrapheBloc9 {
  color:#1A79C6;
}
.pleinBloc9 {
  background-Color:#1A79C6;
}
.videBloc9 {
  border-Color:#1A79C6;  
}
.paragrapheBloc12 {
  color:#1527A2;
}
.pleinBloc12 {
  background-Color:#1527A2;
}
.videBloc12 {
  border-Color:#1527A2;
}
.paragrapheBloc18 {
  color:#0e1a6b;
}
.pleinBloc18 {
  background-Color:#0e1a6b;
}
.videBloc18 {
  border-Color:#0e1a6b;
}
.buttonPrix {
  align-Items: center;
  justify-Content:center;
  border: solid;
  color: #616161;
  border-Width: 0.4px;
  border-Color:#C4C4C4;
  width: 80px;
  height:80px;
  box-Shadow: 5px 5px 5px #C4C4C4;
  margin-Bottom:10px
}
.buttonPrix:active {
  box-shadow: inset -2px -2px 3px rgba(255, 255, 255, .6),
              inset 2px 2px 3px rgba(0, 0, 0, .6);
}
.buttonPrixDefault {
  background-Color:white;
}
.buttonPrixChecked {
  background-Color:#4199EC;
}
.flexLigneButtonPrix {
  margin-right: 30px;
}

/********************************************************************************************************************************
Page OAD : Resistance
********************************************************************************************************************************/
.cardVerte {
  background-Color: #e9f6dc;
}
.enteteOAD{
  display: block;
}
.textbuttonResi {
  font-Size: 25px;
  color: black;
  text-Align:center;
}
.buttonResi {
  display: flex;
  align-Items: center;
  justify-Content:center;
  color: #616161;
  border: solid;
  border-width: 0.1px;
  border-Color:#C4C4C4;
  width: 200px;
  height:80px;
  box-Shadow: 5px 5px 5px #C4C4C4;
  margin-top: 5px;
  margin-bottom: 5px;
  border-Radius: 10px;
  z-index: 1;
}
.buttonResi:active {
  box-shadow: inset -2px -2px 3px rgba(255, 255, 255, .6),
              inset 2px 2px 3px rgba(0, 0, 0, .6);
}
.buttonResiDefault {
  background-Color: white;
}
.buttonResiChecked {
  background-Color: #b1e17c;
}
.button2050degreChecked {
  background-Color: rgb(243, 179, 67);
}
.flexLigne {
  justify-Content: space-between;
  margin-top:10px
}
.flexButtonAss {
  flex-Wrap: wrap;
  margin: 10px;
}
.cardTrans {
  background-Color:#ECECEC;
}
.flexSaisie {
  width:47%;
  margin-Right:10px;
  margin-bottom: 0px;
}
.inputResi {
  background-Color:white;
  border: solid;
  border-Width: 1px;
  border-Color:#CACACA;
  text-Align:center;
  color: #616161;
  width: 70%;
  height: 33px;
  padding: 10px;
  border-Radius: 0px;
  margin-Bottom: 0px;
  margin-Top: 0px;
  margin-Left: 0px;
  font-size: 25px;
}
.rep{
  font-size: 20px;
}
.buttonPM {
  height: 55px;
  width: 55px;
}
.flexLigneOF {
  justify-Content: space-between;
  height:80px;
  border-radius: 15px;
}
.flexLigneV {
  background-Color:#d790fa;
}
.flexLigneJ {
  background-Color:#ffff69;
}
.flexLigneB {
  background-Color:#8bbaff;
}
.flexLigneR {
  background-Color:#5EFFF3;
}
.textOF {
  font-size: 26px;
  padding:10px;
  width: 60%;
}
.TextPM {
  font-size: 35px;
  color:black
}
.buttonRep {
  border:0px;
  overflow: hidden;
  text-overflow: ellipsis; 
  border-Radius: 10px;
  display: flex;
  align-Items: center;
  justify-Content:center;
  box-Shadow: 5px 5px 5px #C4C4C4;
  max-width: 30%;
  width:30%;
  height:50px
}
.buttonRep:active {
  box-shadow: inset -2px -2px 3px rgba(255, 255, 255, .6),
              inset 2px 2px 3px rgba(0, 0, 0, .6);
}
.buttonRep7Default {
  background-Color: #FFC000;
  color: white;
  font-size: 25px;
}
.buttonRep7Checked {
  background-Color: #fbbe0720;
  border: solid #fbbe07;
  color: #fbbe07;
  font-size: 25px;
}
.buttonRep20Default {
  background-Color: #0027CC;
  color: white;
  font-size: 27px;
}
.buttonRep20Checked {
  background-Color: #0027CC20;
  border: solid #0027CC;
  color: #0027CC;
  font-size: 27px;
}
.buttonRep50Default {
  background-Color: #DB04B9;
  color: white;
  font-size: 25px;
}
.buttonRep50Checked {
  border: solid #DB04B9;
  background-Color: #DB04B920;
  color: #DB04B9;
  font-size: 25px;
}
.buttonRepMoyDefault {
  background-Color: #FA8072;
  color: white;
  font-size: 25px;
}
.buttonRepMoyChecked {
  border: solid #FA8072;
  background-Color: #FA807220;
  color: #FA8072;
  font-size: 25px;
}
.buttonCarte:disabled {
  background-color: #e7e7e7;
  border: solid 0.5px#b0b0b0;
  color: #b0b0b0;
}
.blocResi {
  margin-Top:20px;
  width:100%;
}
.titreBlocResi {
  color:black;
  font-Size: 27px;
  display: flex;
  align-items: center;
  text-align: center;
}
.paragrapheBlocResi {
  color:black;
  font-Size: 25px;
  margin-Top:7px;
}
.pleinBlocResi {
  border-Radius: 5px;
  display: flex;
  justify-Content:center;
  margin-Top: -2px;
  height:50%;
  width:100%
}
.videBlocResi {
  border: solid;
  border-Radius: 10px;
  border-Width: 2px;
  width:100%;
  display: flex;
  flex-Direction: column;
  height:125px;
  align-Items:center;
}
.pleinBlocP {
  background-Color:#FF3939;
}
.videBlocP {
  border-Color:#FF3939;
}
.pleinBlocA {
  background-Color:#b1e17c;
}
.videBlocA {
  border-Color:#b1e17c;
}
.pleinBlocRC {
  background-Color:#FF7575;
}
.videBlocRC {
  border-Color:#FF7575;
}
.rotation{
  transform: rotate(180deg) ;
}
.dispLeftResi {
  width: 30%;
  height: 100%;
}
.dispRightResi {
  width: 40%;
  height: 100%;
}
.abscisse_tableau_resistance_rapport{
  font-weight: bold;
  background-color: #b1e17c;
}
.BGWhite{
  background-color: white;
}
.rowResiScalePrincipal {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  align-items: center;
  justify-content: space-around;
}
.rowresiscale{
  width: 100%;
  border: solid 3px ;
  border-radius: 20px;
}
.RRS25 {
  border-color: #CEEAAF;
}
.RRS20 {
  border-color: #7BC361;
}

/********************************************************************************************************************************
Page OAD : Optimisation
********************************************************************************************************************************/
.cardViolet {
  background-Color: #D9F8CA;
}
.cardB {
  background-Color: #C7FFF1;
}
.ligneReco {
  margin-bottom:6px;
  margin-left: 5px;
  justify-content: left;
}
.flexFeux {
  margin-Top:10px;
  align-Items:stretch;
}
.enteteOpti {
  font-Size: 24px;
}
.colEntete {
  flex:1;
  display: flex;
  flex-Direction: column;
  justify-Content:space-between;
}
.bloc {
  display: flex;
  flex-direction: column;
  align-Items:center;
  width:29%
}
.titreEntOpti {
  font-Weight: bold;
}
.titre7 {
  color: #FFC000;
}
.titre20 {
  color: #0027CC;
}
.titre50 {
  color: #DB04B9;
}
.blocColor {
  margin-Top: 10px;
  border-Radius: 10px;
  display: flex;
  align-items: center;
  justify-content:center;
  width:75%;
  height: 40px;
  padding-top:5px;
  padding-bottom: 5px
}
.bloc7 {
  background-Color:#FFC000;
}
.bloc20 {
  background-Color:#0027CC;
  color: white;
}
.bloc50 {
  background-Color:#DB04B9;
  color: white;
}
.Feux {
  width: 50%;
  aspect-ratio: 5/10 ;
  margin-Top: 10px;
}
.Reco {
  width: 8%;
  aspect-ratio: 1/1 ;
  margin-Right:5px;
}
.BlocComparaison{
  width: 100%;
}
.BCL{
  border-right: none;
}
.dispLigne3 {
  margin-bottom: 5px;
  margin-top: 10px;
  align-items: flex-start
}
.dispLigne3Resi{
  justify-content: flex-start;
}
.containerButtonPrint{
  margin-top: 80px;
}
.buttonPrint{
  background-color: #92D050;
  width: 5%;
  aspect-ratio: 8/7;
  border-radius: 20px;
  border: none;
  position: sticky;
  top: 125px;
  left: 93%;
  z-index: 20;
}
.ButtonQuestions{
  background-color: #92D050;
  width: 50px;
  height:50px;
  border-radius: 100px;
  border: none;
  position: sticky;
  bottom: 150px;
  left: 93%;
  z-index: 15;
}
.titrePlusVideo{
  display: flex;
  align-items: center;
}
.ButtonCam{
  margin-left: 10px;
  background-color: #92D050;
  width: 40px;
  height:40px;
  border-radius: 100px;
  border: none;
}

.validationCout{
  background-Color: #92D050;
  border-Radius: 10px;
  padding: 10px;
  width: 100%;
  font-size: 23px;
  box-Shadow: 5px 5px 5px #C4C4C4;
  display: flex;
  align-Items: center;
  justify-Content:center;
  color: #616161;
  border: solid;
  height: 80px;
  margin-top: 20px;
}
.validationClicked {
  background-color: white;
  color: #92D050;
  border-width: 2px;
  border-color: #92D050;
}
.validationDefaut {
  background-color: #92D050;
  color: #616161;
  border-width: 0px;
  box-Shadow: 5px 5px 5px #C4C4C4;
}
.optiLeft{
  width: 100%;
}
.optiRight {
  width: 100%;
}
/********************************************************************************************************************************
Page OAD : Optimisation d'assolement
********************************************************************************************************************************/
.ShowResult{
  display: block;
}
.ShowResultFlex{
  display: flex;
  justify-content: space-between;
}
.HideResult {
  display: none;
}
.showflex{
  display: flex;
  align-items: center;
}
.paragrapheAlignGauche{
  align-items: flex-start;
}
.validationCompAssol{
  width: 35%;
  margin: 0px;
  border-color: #a1a1a1;
}
.valCompVert {
  background-color: rgb(179, 231, 148);
}
.valCompVert:hover {
  background-color: rgb(142, 227, 92);
}
.valCompOrange {
  background-color: #FBE5D6;
}
.valCompOrange:hover {
  background-color: #ffd0b0;
}
.validationCompGCVITIMI{
  margin-top: 5px;
  margin-left: 5px;
  margin-right: 5px;
  width: 17%;
}
.valCompGCC{
  background-color: #B48100;
  color: white;
}
.valCompGCC:hover{
  background-color: #d39906;
}
.valCompGCUnC{
  background-color: white;
  color: #B48100;
  border: solid 1px #B48100;
}
.valCompGCUnC:hover{
  background-color: #ffe39d;
}
.valCompVitiC{
  background-color: #990900;
  color: white;
}
.valCompVitiC:hover{
  background-color: #ac241b;
}
.valCompVitiUnC{
  background-color: white;
  color: #990900;
  border: solid 1px #990900;
}
.valCompVitiUnC:hover{
  background-color: #ffb2ac;
}
.valCompMixteC{
  background-color: #7DA455;
  color: white;
}
.valCompMixteC:hover{
  background-color: #96c06c;
}
.valCompMixteUnC{
  background-color: white;
  color: #7DA455;
  border: solid 1px #7DA455;
}
.valCompMixteUnC:hover{
  background-color: #ddffbc;
}
.divBalance {
  display: inline-block;
}
.divTreeMap{
  display: inline-block;
  position: relative;
  top:50px
}
.testBGI{
  background-image: url("./assets/balance-equiv.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 85%;
  position:relative;
  width: 100%;
  height: 700px;
}
.imagePodium{
  width: 100%;
  height: 600px;
}


/********************************************************************************************************************************
Page OAD : Aléas
********************************************************************************************************************************/
.containerAleas{
  margin-left: 10%;
}
.ongletaléas_INAC{
  width: 65%;
  border: solid white 0.5px;
  background-color: #92D050;
  height: 60px;
  text-align: center;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
  display: flex;
  flex-direction: column;
  padding: 2px;
  align-items: center;
  justify-content: center;
}
.ongletaléas_AC{
  border: solid #92D050 0.5px;
  background-color: white;
  height: 150px;
  width: 95%;
}
.ongletLOGOaléas_INAC{
  height: 90%;
}
.ongletLOGOaléas_AC{
  height: 50%;
}
.buttonsFixedAleas {
  height:100%;
  width: 10%;
  position:fixed;
  margin-bottom: 0px;
  left: 0;
  top:200px;
  z-index: 15;
}
.OAléasSH_AC{
  border: solid #00F1F1 0.5px;
}
.OAléasSH_INAC{
  background-color: #00F1F1;
}
.OAléasEE_AC{
  border: solid  #002FF1 0.5px;
}
.OAléasEE_INAC{
  background-color: #002FF1;
}
.OAléasGEL_AC{
  border: solid #00E57D 0.5px; 
}
.OAléasGEL_INAC{
  background-color: #00E57D;
}
.OAléasCDC_AC{
  border: solid #F19600 0.5px;
}
.OAléasCDC_INAC{
  background-color: #F19600;
}
.OAléasMR_AC{
  border: solid #EDF100 0.5px;
}
.OAléasMR_INAC{
  background-color: #EDF100;
}
.OAléasPodium_AC{
  border: solid #8E44AD 0.5px;
}
.OAléasPodium_INAC{
  background-color: #8E44AD;
}
.dateSemisUneCulture:disabled {
  background-color: rgb(233, 233, 233);
  color: #3d3d3d;
}
.imageC_ou_A_Podium{
  width:100px
}
.imagePodium{
  width: 100%;
  height: 400px;
}
.elementspodium{
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.imageAleaPodium{
  width: 55%;
}
.PanelCommandeAlea{
  border: solid 3px black;
  border-radius: 20px;
  margin-left: 10px;
  margin-right: 10px;
}
.PCACulture{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  margin-right: 15%;
  border-color: #D050B9;
}
.PCADonnes{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 15%;
  border-color: #92D050;
}
.PCADegre{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 10%;
  border-color: #F3B343;
}
.PODIUMCA{
  justify-content:space-between; 
  align-items:flex-start; 
  background-image: url("./assets/podium.png");
  background-repeat: no-repeat; 
  background-position: bottom; 
  background-size: 85%; 
  position: relative; 
  width: 700px; 
  height:400px; 
  flex-direction:column; 
  margin-top:150px; 
  margin-bottom:50px; 
  margin-left:5%
}
.PODIUMCouA{
  justify-content:space-between; 
  align-items:flex-start; 
  background-image: url("./assets/podium.png");
  background-repeat: no-repeat; 
  background-position: bottom; 
  background-size: 85%; 
  position: relative; 
  width: 700px; 
  height:400px; 
  flex-direction:column; 
  margin-top:150px; 
  margin-bottom:50px; 
  margin-left:5%
}
.rowPODIUMCouA{
  justify-content:space-around;
  margin-top: 100px;
  margin-left: 5%;
  width: 70%;
}
.imagePodiumCA2 {
  margin-top: -50px;
}
.imageVidePodiumCA2 {
  margin-top: 0px;
}
.imagePodiumCA1 {
  margin-top: -120px;
}
.imagePodiumCA3 {
  margin-top: 50px;
}
.imageVidePodiumCA3 {
  margin-top: 120px;
}
.RDTPodiumCA{
  margin-top:23%;
  justify-content:space-evenly;
  width:100% ; 
  text-align:center;
}
.imagePodium_C_ou_A_2 {
  margin-top: 0px;
}
.imageVidePodium_C_ou_A_2 {
  margin-top: 0px;
}
.imagePodium_C_ou_A_1 {
  margin-top: -80px;
}
.imagePodium_C_ou_A_3 {
  margin-top: 85px;
}
.imageVidePodium_C_ou_A_3 {
  margin-top: 120px;
}
.RDTPodium_C_ou_A{
  margin-top:23%;
  justify-content:space-evenly;
  width:100% ; 
  text-align:center;
}
.titrePodium{
  width:100%; 
  text-align: center;
  margin-top: 5px;
}
.AleasPodium{
  width: 100%;
}
.AnneePodium{
  width:100%; 
  display: flex; 
  flex-direction:column; 
}
.RCPPodium{
  width: 100%;
}


/********************************************************************************************************************************
Page OAD : Solutions
********************************************************************************************************************************/
.blocCultureSolutions{
  width: 120%;
  background-color: #D9FFB1;
  margin-top: 10px;
  padding: 5px;
  padding-right: 1px;
}
.blocCultureCCult{
  width:60%; 
  margin-left: 18%; 
  margin-top:20px;
}
.texteCultureSolutions {
  padding-left: 5%;
  font-weight: normal;
  width: 100%;
}
.OAléasIRRIG_AC{
  border: solid #00A7FA 0.5px;
}
.OAléasIRRG_INAC{
  background-color: #00A7FA;
}
.OAléasESQ_AC{
  border: solid #E366FF 0.5px;
}
.OAléasESQ_INAC{
  background-color: #E366FF;
}
.OAléasCCep_AC{
  border: solid #B30000 0.5px;
}
.OAléasCCep_INAC{
  background-color: #B30000;
}


/********************************************************************************************************************************
Page comparaison de simulation
********************************************************************************************************************************/
.menuComparaison{
  height: 300px;
  width: 65px;
  position: fixed;
  background-color: #dadada76;
  top: 120px;
  right: 10px;
  z-index: 2;
}
.menuComparaison2050{
  height: 225px;
  width: 65px;
  position: fixed;
  background-color: #dadada76;
  top: 120px;
  right: 10px;
  z-index: 2;
}
.buttonMenuComp{
  border: 0px;
  height: 20%;
  margin-left: 10px;
  background-color: transparent;
}
.buttonMenuComp2050{
  border: 0px;
  height: 25%;
  margin-left: 10px;
  background-color: transparent;
}
.iconMenuComp{
  height: 60%;
}
.no-left-margin .rv-xy-plot__inner {
  margin-left: 0 !important;
}

.ordonnée_tableau_prix_rapport{
  width: 22%;
  height: 55px;
  border: solid #A6A6A6;
  border-Width: 1.5px;
  display: flex;
  align-items: center;
  padding-left: 5px;
  font-size: 20px;
}
.ordonne_2050{
  width: 15%;
}
.ordonne_ZI{
  width: 20%;
}
.data_tableau_prix_rapport{
  width: 16%;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid #A6A6A6;
  border-Width: 1.5px;
  margin-left: -1px;
  font-size: 20px;
}
.data_tableau_carte_raport{
  width: 16%;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid #A6A6A6;
  border-Width: 1.5px;
  margin-left: -1px;
  font-size: 20px;
}
.titretableauresi{
  height: 60px;
}
.abscisse_tab_2050{
  font-size: 16px;
}
.data_tableau_2050{
  width: 17%;
}
.data_tableau_ZI{
  width: 20%;
}
/********************************************************************************************************************************
Cartes par culture
********************************************************************************************************************************/
.rowNumberCarte{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.blocAnneeCartes{
  width: 50%;
}
.BlocRCPCartes{
  width: 10%;
}
.buttonAnneeCartesChecked {
  background-Color: #50D0B9;
  color: black;
  font-size: 25px;
}
.buttonAnneeCartesDefault {
  background-Color: white;
  color: #616161;
  border: solid;
  border-width: 0.1px;
  border-Color: #C4C4C4;;
  font-size: 25px;
}
.buttonRCPCartesChecked {
  background-Color: #00E57D;
  color: black;
  font-size: 25px;
}
.buttonRisqueOUIrrigCartesChecked {
  background-Color: #77D6FF;
  color: black;
  font-size: 25px;
}
.buttonRCPCartesDefault {
  background-Color: white;
  color: #616161;
  border: solid;
  border-width: 0.1px;
  border-Color: #C4C4C4;
  font-size: 25px;
}
.tableauCarteParCultureHierar{
  width: 65%;
}
.ordonne_2050CartesParCultureHierar{
  width: 30%;
}
.data_tableau_2050CartesParCultureHierarRDT{
  width: 10%;
}
.data_tableau_2050CartesParCultureHierar{
  width: 20%;
}
.switchcoloneLigne{
  width:100%
}

/********************************************************************************************************************************
Manuel de découverte
********************************************************************************************************************************/
.ligneGCVMD{
  margin-top: 10px;
}
.BTGCVMD{
  width: 45%;
}
.ligneMD{
  margin-top: 25px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.ongletMD_INAC{
  width: 19.3%;
  border: solid white 0.5px;
  background-color: #92D050;
  height: 100px;
  text-align: center;
  margin-top: 10px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  display: flex;
  padding: 2px;
  align-items: center;
  justify-content: center;
}
.ongletMD_Cult_INAC{
  width: 25%;
  color : black;
  padding: 0px;
  margin: 0px;
}
.ongletMD_AC{
  border: solid #92D050 0.5px;
  background-color: white;
}
.ongletMD_BT_AC{
  border: solid #FFD705 0.5px;
  background-color: white;
}
.ongletMD_BD_AC{
  border: solid #F8BE3E 0.5px;
  background-color: white;
}
.ongletMD_OH_AC{
  border: solid #FFFF00 0.5px;
  background-color: white;
}
.ongletMD_OP_AC{
  border: solid #FFF385 0.5px;
  background-color: white;
}
.ongletMD_COLZA_AC{
  border: solid #D7A657 0.5px;
  background-color: white;
}
.ongletMD_VITI_AC{
  border: solid #F00000 0.5px;
  background-color: white;
}
.ongletMD_BT_INAC{
  background-color: #FFD705;
  border: solid white 0.5px;
}
.ongletMD_BD_INAC{
  background-color: #F8BE3E;
  border: solid white 0.5px;
}
.ongletMD_OH_INAC{
  background-color: #FFFF00;
  border: solid white 0.5px;
}
.ongletMD_OP_INAC{
  background-color: #FFF385;
  border: solid white 0.5px;
}
.ongletMD_COLZA_INAC{
  background-color: #D7A657;
  border: solid white 0.5px;
}
.ongletMD_VITI_INAC{
  background-color: #F00000;
  border: solid white 0.5px;
}
.onglet_DISABLE {
  background-color: #e7e7e7;
  color: #b0b0b0;
}
.containerSlideManuel{
  width: 100%;
  border: solid #92D050 2px;
}
.LigneimgManuel{
  display: flex;
  flex-wrap: wrap;
}
.ButonCrossMD{
  position: relative;
  left: 97%;
  top: 5px;
}
.imgManuel{
  margin-top: 15px;
  margin-left: 5px;
  margin-bottom: 5px;
  width: 90%;
}


/********************************************************************************************************************************
Rapport PDF
********************************************************************************************************************************/
.imageClassementPodium{
  width: 25%;
  margin-top: 10px;
  margin-bottom: 10px;
}
.bloc-afom-rapport{
  width: 48.5%;
  height: 650px;
  border: solid black 3px;
}
.lettre-rapport-AFOM{
  width: 25%;
}
.bloc-afom-rapport-A{
  border-color: #83BE29;
}
.bloc-afom-rapport-F{
  border-color: #CA0047;
}
.bloc-afom-rapport-O{
  border-color: #01D6A3;
}
.bloc-afom-rapport-M{
  border-color: #E37006;
}
.pageDeGarde {
  display: none;
}
.image_how_rapport{
  width: 40%;
}
.couv4eme {
  display : none ;
}
.titre_scetion_rapport{
  color:#92D050; 
  width: 98%;
  margin-right: 2%;
  font-size: 33px;
  font-weight: bold;
  padding-bottom: 10px;
  border-bottom: #92D050 solid 4px ;
  margin-bottom: 10px;
}
.pointHow {
  margin-bottom: 10px;
  font-size: 20px;
}
.commentaireRapport{
  border: #92D050 solid 3px;
  padding: 7px;
  font-size: 20px;
  margin-right: 20px;
  margin-bottom: 30px;
}
.col_libelle_rapport {
  width: 32%;
}
.col_surface_rapport {
  display: flex;
  justify-content: center;
  width: 17%;
}
.col_rdt_rapport {
  display: flex;
  justify-content: center;
  width: 17%;
}
.col_prix_rapport {
  display: flex;
  justify-content: center;
  width: 17%;
}
.col_PB_unitaire_rapport {
  display: flex;
  justify-content: center;
  width: 17%;
}
.col_assol_rapport{
  font-size: 20px;
}
.num_page_rapport{
  display: none;
}
.row_prix_rapport{
  width: 1000px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.graphique_titre_prix_rapport{
  font-size: 30px;
  font-weight: bold;
  margin-left: 50px;
}
.graph_prix_rapport {
  width: 100%;
}
.enlevemarg{
  margin-bottom: 0px;
}
.tableau_rendement_rapport{
  margin-bottom: 30px;
  margin-top: 15px;
  width: 90%;
  margin-right: 20px;
  margin-left: 5%;
}
.tableau_podium_rapport{
  margin-bottom: 30px;
  margin-top: 15px;
  width: 96%;
  margin-right: 2%;
  margin-left: 2%;
  font-size: 18px;
}
.data_tableau_rdt_rapport{
  width: 24%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #7F7F7F;
  border: solid #77933C;
  border-Width: 1.5px;
  margin-left: -1px;
}
.data_tableau_podium_rapport{
  width: 33%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #3f3f3f;
  border: solid #3f3f3f;
  border-width: 1px;
}
.data_tableau_podium_rapport_entete{
  width: 33%;
  display: inline-block;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #3f3f3f;
  border: solid #3f3f3f;
  border-width: 1px;
}
.imageAleaPodiumRapport{
  width: 30%;
}
.color-tab-podium-35-4D {
  background-color: #db94fc;
}
.color-tab-podium-35-2D {
  background-color: #f4dbff;
}
.color-tab-podium-50-4D {
  background-color: #FF6969;
}
.color-tab-podium-50-2D {
  background-color: #ffacac;
}
.ordonnée_tableau_rdt_rapport{
  width: 25%;
  border: solid #77933C;
  color: white;
  font-weight: bold;
  text-align: center;
  border-Width: 1.5px;
  display: flex;
  align-items: center;
  padding-left: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.titre_col_rdt_rapport{
  height: 60px;
}
.vous_tableau_rdt_rapport{
  background-color: #EBF1DE;
  color: black;
}
.row_tableau_prix_rapport{
  display: flex;
  flex-direction: row;
  align-items: center;
}
.tableau_prix_rapport{
  margin-bottom: 30px;
  margin-top: 5px;
  margin-right: 20px;
}
.tableau_carte_rapport{
  width: 70%;
}
.abscisse_tableau_prix_rapport{
  color: white;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
}
.carte_rapport_titre{
  text-align: center;
  width: 99%;
  justify-content: center;
}
.carte_rapport{
  width: 33%;
  height: 300px;
}
.totalCarteRapport{
  width: 95%;
}
.HeightMapRiskRdt{
  height: 650px;
  margin-top: 20px;
}
.HeightMap{
  height: 280px;
  margin: 0px;
}
.legendCarte_RiskRDT_rapport{
  width: 350px;
  margin-top: 5px;
  margin-bottom: 10px;
}
.titre_ligne_tableau_resi {
  width: 20%;
  height: 50px;
  border: solid #A6A6A6;
  border-Width: 1.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: bold;
}
.contenu_resi_tableau_rapport{
  font-weight: normal;
  height: 40px;
}
.multicolor {
  background-image: linear-gradient(to right, #5EFFF3, #8BBAFF, #FFFF69, #D790FA);
}


/********************************************************************************************************************************
Mise en page en format paysage : tablette et mobile en horizontal + PC
********************************************************************************************************************************/
@media screen and (orientation: landscape) {
  h1 {
      font-size: 27px;
  }
  h2 {
      font-size: 1.2em;
  }
  .accueil {
    padding-top: 60px;
  }
  .navSW{
    width: 100%;
    position: fixed;
    top: 0;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #9AE748;
  }
  .copyright{
    height: 30px;
  }
  .logo_pin_Footer {
    width: 8%;
  }
  .logo_contact {
    width: 10%;
  }
  .logo_perqo_Footer {
    width: 50%;
  }
  .paragrapheAccueil{
    font-size: 25px;
  }
  .footer_gauche_centre {
    display: block;
    width: 20%;
    padding-left: 3%;
  }
  .footer_gauche {
    width: 20%;
  }
  
  .buttonEntite{
    height: 40px;
  }
  .footer_centre {
    width: 19%;
  }
  .footer_droit {
    width: 25%;
  }
  .buttonFooter {
    font-size: 16px;
  }
  .liste{
    width: 80%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    list-style: none;
    margin-top: 0px;
  }
  .liste2{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    list-style: none;
    margin-top: 0px;
  }
  .listeAccueilgauche{
    justify-content: flex-start;
  }
  .item{
    font-weight: bold;
    margin: 0.1rem;
    color: black;
    cursor: pointer;
    transition: 1s ease-in-out;
  }
  .navSW_burger{
    display: none;
  }
  .item{
    font-weight: bold;
    margin: 0.1rem;
    margin-right: 0.7rem;
    color: black;
    cursor: pointer;
    transition: 1s ease-in-out;
  }
  .textNavAccueil{
    display: none;
  }
  .logoNavAccueil{
    display: inline;
  }
  .ButtonEnteteNav{
    font-size: 15px;
  }
  .buttonRightEnteteNav{
    font-size: 15px;
  }
  .show-nav .liste {
    display: flex;
  }
  .hide-nav .liste{
    display: flex;
  }
  .propositionValeur{
    font-size: 35px;
  }
  .titreSW{
    margin-left: 0%;
    margin-right: 0%;
    margin-top: 0%;
  }
  .sous-Titre-SW{
    font-size: 35px;
  }
  .sous-sous-titre-SW{
    font-size: 25px;
  }
  .imageprojetARRUP{
    width: 15%;
  }
  .texteARRUP{
    width: 35%;
  }
  .imgcardaccueil2{
    width: 70%;
  }
  .prghduoaccueil{
    width: 80%;
    text-align: justify;
  }
  .BTGCVMD{
    width: 17%;
  }
  .ongletMD_INAC{
    width: 9.56%;
    font-size: 15px;
    height: 60px;
    margin-top: 10px;
  }
  .ongletMD_Cult_INAC{
    width: 10.97%;
  }
  .imgManuel{
    width: 45%;
  }
  .tableauAbo{
    margin-left: 15%;
    margin-right: 15%;
    margin-top: 110px;
  }
  .LogoOffres {
    width: 30%;
  }
  .LogoOuiNon{
    margin-top: 5px;
    margin-bottom: 5px;
    width: 25%;
  }
  .enteteLigneAbo{
    height: 60px;
  }
  .celluleInfoAbo{
    height: 60px;
  }
  .celluleTitreAbo{
    height: 60px;
  }
  .cardAboPremiumRecap{
    padding: 35px;
    width:70%;
  }
  .IconMenuAccueil {
      width: 60%;
  }
  .textCardAccueil2 {
    font-size: 25px;
    width: 50%;
    margin-right: 10px;
  }
  .textCardAccueil3 {
    width: 100%;
    margin-right: 0px;
  }
  .titreBleuAccueil{
    font-size: 23px;
  }
  .titreImage1Accueil{
    font-size: 23px;
  }
  .titreImage2Accueil{
    color: white;
    width: 25%;
    margin: 0px;
  }
  .imageAccueil {
    width: 40%;
    border-radius: 6px
  }
  .PODIUMCA{
    width: 450px; 
    height:250px; 
    margin-top:60px; 
    margin-bottom:50px; 
    margin-left:33%
  }
  .PODIUMCouA{
    width: 450px; 
    height:250px; 
    margin-top:60px; 
    margin-bottom:50px; 
    margin-left:0%
  }
  .rowPODIUMCouA{
    justify-content:space-around;
    margin-top: 100px;
    margin-left: 0%;
    width: 100%;
  }
  .imagePodiumCA2 {
    margin-top: -10px;
  }
  .imageVidePodiumCA2 {
    margin-top: 0px;
  }
  .imagePodiumCA1 {
    margin-top: -60px;
  }
  .imagePodiumCA3 {
    margin-top: 30px;
  }
  .imageVidePodiumCA3 {
    margin-top: 80px;
  }
  .RDTPodiumCA{
    margin-top:23%;
    justify-content:space-evenly;
    width:100% ; 
    text-align:center;
  }
  .imagePodium_C_ou_A_2 {
    margin-top: 15px;
  }
  .imageVidePodium_C_ou_A_2 {
    margin-top: 0px;
  }
  .imagePodium_C_ou_A_1 {
    margin-top: -30px;
  }
  .imagePodium_C_ou_A_3 {
    margin-top: 60px;
  }
  .imageVidePodium_C_ou_A_3 {
    margin-top: 80px;
  }
  .RDTPodium_C_ou_A{
    margin-top:23%;
    justify-content:space-evenly;
    width:100% ; 
    text-align:center;
  }
  .titrePodium{
    width:100%; 
    text-align: center;
    margin-top: 5px;
  }
  .AleasPodium{
    margin-right: 5%;
    width: 35%;
  }
  .AnneePodium{
    width:30%; 
    margin-left:30px; 
    display: flex; 
    flex-direction:column; 
    margin-right:5%
  }
  .RCPPodium{
    width: 15%;
  }
  .principal {
    flex-Direction:row;
    justify-content : space-around;
    align-items: center;
  }
  .textboxHome{
    width:50%;
  }
  .videoHome{
    width:30%;
  }
  .principalBis {
    display: flex;
    flex-Direction:row;
    justify-content : space-between;
  }
  .buttonAccueil {
      width:100% ;
      margin-right: 50px;
      height: 70px;
      font-size: 20px;
  }
  .paragrapheNotFound {
    font-size: 20px;
  }
  .containerNotFound{
    margin-top: 7%;
  }
  .menuComparaison{
    top: 85px;
  }
  .top {
      display: flex;
      flex-direction: column;
      width: 50%;
      justify-content: center;
  }
  .topBis {
    align-items: center;
  }
  .topBisBis{
    width: 20%;
  }
  .topBBis{
    width: 40%;
  }
  .App-logo {
      width: 75%;
  }
  .App-logo2 {
    width: 60%;
  }
  .bottom {
      width: 40%;
      justify-content: center;
  }
  .bottom2 {
    width: 45%;
  }
  .bottom3{
    width: 80%;
    justify-content: center;
    text-align: start;
    align-items: flex-start;
    margin-left: 2%;
  }
  .entete {
      height: 50px;
  }
  .enteteSW{
    height: 70px;
  }
  .menuOad {
      height:65px;
  }
  .ongletaléas_INAC{
    height: 45px;
  }
  .ongletLOGOaléas_AC{
    height: 60%;
  }
  .ongletaléas_AC{
    height: 80px;
  }
  .buttonsFixedAleas {
    top:80px;
  }
  .SOmenuOad {
    width: 18%;
    right: 0;
    left: 82%;
    bottom: 65px;
  }
  .nomMenuOAD {
      font-size: 15px;
  }
  .IconMenuOAD {
      height: 60%;
      margin-top: 5px;
  }
  .LogoPin {
    height: 25px;
    margin-right: 10px;
  }
  .ligne_assol {
    margin-left: 0px;
    margin-right: 0px;
  }
  .masquer_en_portrait{
    display: flex;
    flex-direction: column;
  }
  .col_assol {
    font-size: 18px;
    padding: 5px;
  }
  .col_libelle {
    width: 20%;
  }
  .col_libelle_entete {
    display: flex;
    width: 19%;
    justify-content:center;
  }
  .col_libelle_cult_plus {
    width: 17.5%;
  }
  .col_suppr_cult_plus {
    display: flex;
    width: 2%;
  }
  .col_spe{
    display: flex;
    width: 20%;
    justify-content:center;
  }
  .col_spe_entete{
    display: flex;
    width: 19%;
    justify-content:center;
  }
  .col_TotFinal{
    width: 44%;
  }
  .select_assol{
    margin-Bottom:0px;
    margin-Top: 0px;
    margin-Left:0px;
    width: 95%;
    height: 40px;
    font-size: 100%;
  }
  .col_surface {
    display: flex;
    justify-content: center;
    width: 11%;
  }
  .col_semis{
    width: 11%;
    display: flex;
    justify-content: flex-end;
  }
  .showButtonSemisFalse{
    display:none
  }
  .showButtonSemisTrue{
    display:flex
  }
  .col_buttonSemis{
    display: flex;
  }
  .col_rdt {
    display: flex;
    justify-content: center;
    width: 11%;
  }
  .col_prix {
    display: flex;
    justify-content: center;
    width: 11%;
  }
  .col_PB_unitaire {
    display: flex;
    justify-content: center;
    width: 10%;
  }
  .col_PB_tit_Prod{
    display: flex;
    justify-content: center;
    width: 10%;
  } 
  .col_PB_tot {
    display: flex;
    justify-content: flex-end;
    width: 48%;
    margin-right: 2%
  }
  .BCL{
    border-right: solid;
  }
  .pleinBlocAssol {
    width:40%;
    margin-left: 0px;
  }
  .videBlocAssol {
    width:90%;
    height:40px;
  }
  .input_assol {
    font-size: 18px;
    height: 35px;
    width: 60%;
  }
  .input_assol_semis{
    border-width: 1px;
    height: 40px;
    margin: 0px;
  }
  .col_check{
    width: 7%;
  }
  .col_date {
    display: block;
    width: 10%;
  }
  .col_libelle_simul {
     width: 10%;
  }
  .col_assol_culture {
    display: block;
    width: 40%;
  }
  .col_button {
    width: 40%;
  }
  .container{
      margin-Top: 60px;
      margin-bottom: 100px;
  }
  input, select {
      font-size: 125%;
      width: 95%;
      margin-bottom: 25px;
  }
  .validation {
      font-size: 17px;
      overflow: hidden;
      text-overflow: ellipsis; 
  }
  .validationCompSimul{
    font-size: 18px;
  }
  .paragraphe {
    font-size: 16px;
  }
  .paragrapheSmaller{
    font-size: 14px;
  }
  .paragrapheLanding{
    font-size: 20px;
  }
  .paragrapheSmaller{
    font-size: 12px;
  }
  .paragrapheAleas {
    font-size: 13px;
  }
  
  .BlocRCPCartes{
    width: 10%;
    margin-right: 250px;
  }
  .buttonOngletMD{
    font-size: 16px;
  }
  .titre {
      font-size: 24px;
  }
  .titrePlus {
      font-size: 28px;
  }  
  .titreMoins {
      font-size: 22px;
  }
  .titreMoinsMoins {
    font-size: 18px;
  }
  .textX {
      font-size: 20px;
  }
  .pleinBloc {
    width: 50%;
  }
  text{
    font-size: 16px;
  }
  .titreBloc {
      font-Size: 20px;
  }
  .paragrapheBloc {
    width: 50%;
      font-Size: 24px;
  }
  .leaflet-container{
    width: 100%;
  }
  .flexLigneBtnRdt {
    flex-direction: column;
    margin-right: 20px;
  }
  .buttonCarte {
    margin-right: 0px;
    margin-bottom: 10px;
    width: 100%;
  }
  .buttonLegendeCarte {
    margin-left: 0px;
    width: 150px;
  }
  .blocCarte{
    display: flex;
    justify-content: flex-start;
    width: 100%;
  }
  .totalCarte{
    width: 70%;
  }
  .buttonEntite {
    height: 60px;
    margin-left: 10px;
  }
  .videBlocPrix {
      height:100px;
  }      
  .paragrapheBlocPrix {
      font-Size: 24px;
  }
  .textbuttonResi {
      font-Size: 19px;
  }
  .textOF {
      font-size: 20px;
  }
  .inputResi {
      font-size: 21px;
  }
  .rep{
      font-size: 20px;
  }
  .degre_Solution2050{
    font-size: 16px;
  }
  .titreBlocResi {
      font-Size: 21px;
  }
  .paragrapheBlocResi {
      font-Size: 22px;
  }
  .titreCard {
      font-size: 19px;
  }
  .enteteOpti {
      font-Size: 21px;
  }
  .Feux {
      width: 40%;
  }
  .navbarSW_lien {
    font-size: 3vw;
  }
  .navbarSW_lienAdmin{
    font-size: 2vw;
  }
  .burger-bar, 
  .burger-bar::before, 
  .burger-bar::after {
    width: 40px;
  }
  .navbarSW_burger {
    width:50px;
    height: 50px;
  }
  .burgerOAD {
    width: 50px;
    height: 40px;
  }
  .titrOffreEmplois{
    font-size: 23px
  }
  .QSM_p {
    font-size: 18px;
  }
  .Mains {
    width:53%;
    aspect-ratio: 100/151;
    object-fit: cover;
  }
  .Prot{
    aspect-ratio: 680/185;
  }
  .method{
    width: 100%;
  }
  .checkInscription{
    width: 4%;
    height: 25px;
  }
  .textInscription {
    font-size: 18px;
  }
  .inscriptionVal {
    text-align: center; 
    margin-Top: 300px;
    font-Size: 30px;
  }
  .titreQuestion {
    font-size: 22px;
  }
  .buttonPrint{
    top: 70px;
    left: 94%;
  }
  .ButtonQuestions{
    bottom: 100px;
    left: 94%;
  }
  .containerButtonPrint{
    margin-top: -10px;
  }
  .App-logo_solution {
    width: 30%;
  }
  .video_prez_DR{
    width: 45%;
    margin-left: 3px;
    margin-left: 3px;
    margin-bottom: 0px;
  }
  .rowSolutions{
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .firstLigneSolG{
    width: 60%;
  }
  .firstLigneSolD{
    width: 40%;
    margin-left: 30px;
  }
  .Sol_p{
    font-size: 20px;
    margin-left: 15px;
    margin-right: 15px;
  }
  .Sol_icon{
    height: 150px;
    width: 150px;
  }
  .column_sol{
    width: 100%;
  }
  .devices_rdt{
    width: 40%;
    display: flex;
    order: 2;
  }
  .ligneLogoSol{
    width: 100%;
    display: flex;
    order: 1;
  }
  .Sol_logoS{
    width: 30%;
    margin-right: 20px;
    margin-bottom: 0px;
  }
  .ButtonShowHide {
    right: 40px;
    top: 460px;
  }
  .ButtonShowHideConnexion{
    right: 70px;
    top: 450px;
  }
  .ButtonShowHide2 {
    right: 60px;
    top: 90px;
  }
  .ButtonShowHide3 {
    right: 80px;
    top: 120px;
  }
  #popup_carte_riskRDT {
    font-size: 15px;
  }
  .texteGauche{
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 1%;
  }
  .imageDroite {
    width: 20%;
  }
  .rowBlog{
    display: flex;
    flex-direction: row;
  }
  .imageBlog {
    width:100%;
    aspect-ratio: 1;
    object-fit: cover;
  }
  .rowResiScalePrincipal {
    flex-direction: row;
  }
  .rowresiscale {
    width: 45%;
  }
  .ordonnée_tableau_prix_rapport{
    font-size: 17px;
    height: 35px;
  }
  .data_tableau_prix_rapport{
    font-size: 17px;
    height: 35px;
  }
  .optiLeft{
    width: 40%;
  }
  .optiRight {
    width: 60%;
  }
  .tarifHT{
    font-size: 25px;
  }
  .tarifparpers{
    font-size: 20px;
  }
  .rowTarifRecap{
    flex-wrap:nowrap;
  }
  .tarifHTPers{
    width: 40%;
  }
  .boutonsRecap{
    width: 40%;
    justify-content: center;
  }
  .boutonRecap{
    justify-content: center;
  }
  .encartProfil{
    width: 10%;
    padding: 5px;
    position: fixed;
    top: 50px;
    right: 0;
    border: solid #a1a1a1 0.5px;
  }
  .rowNumberCarte{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .blocAnneeCartes{
    margin-left: 250px;
  }
  .BlocRCPCartes{
    margin-right: 15%;
  }
  .popupLegend{
    width: 17%;
  }
  .legendCarte_RiskRDT2050 {
    width: 400px;
  }
  .popupLegend2050{
    width: 25%;
    top: 50%;
  }
  .popupConsentement{
   width: 90%;
  }
  .switchcoloneLigne{
    width:90%
  }
  .buttonCheckedSimul{
    width: 40%;
  }
  .blocCultureCCult{
    width:40%; 
    margin-left: 25%; 
    margin-top:20px;
  }
  .texteCultureSolutions {
    padding-left: 5%;
    width: 100%;
    font-weight: normal;
  }
  @media screen and (min-width: 550px) {
    .dispLigne {
      display: flex;
      flex-direction: row;
      margin-bottom: 5px;
    }
    .dispLeft {
      width: 48%;
    }
    .BlocComparaison{
      width: 50%;
    }
    .dispRight {
      width: 48%;
      margin-left: 10px;
    }
    .dispLeft2050 {
      width: 50%;
    }
    .dispRight2050 {
      width: 50%;
      margin-left: 20px;
    }
    .buttonPrix{
      height: 55px;
      width: 55px;
    }
    .active {
      font-size: 27px;
    }
    .active2 {
      font-size: 22px;
    }
    .titrePrix {
      font-Size: 19px;
      text-Align: right; 
      width: 20%;
    }
    .dispLeftOpt {
      width: 64%;
    }
    .dispRightOpt {
      width: 35%;
      margin-left: 10px;
    }
    .flexFeux {
      margin-Top:30px;
    }
    .cellBCPTextAbo{
      height: 130px;
    }
  }
  @media screen and (min-width: 1400px) {
    
  }
  @media screen and (max-width: 1550px) {
    .ongletMD_Cult_INAC{
      width: 10.5%;
    }  
    .popupLegend{
      top: 50%;
    }
    .legendCarte_RiskRDT2050 {
      width: 400px;
    }
    .popupLegend2050{
      width: 37%;
      top: 55%;
    }
    .HeightMapRiskRdt{
      height: 450px;
    }
    .buttonEntite{
      height: 50px;
    }
    .titreSW{
      margin-left: 0%;
      margin-right: 0%;
      margin-top: 0%;
    }
    .sous-Titre-SW{
      font-size: 25px;
    }
    .sous-sous-titre-SW{
      font-size: 20px;
    }
    .paragraphe {
      font-size: 15px;
    }
    .paragrapheSmaller{
      font-size: 13px;
    }
    .titre {
      font-size: 21px;
    }
    .titrePlus {
        font-size: 23px;
        margin-top: 10px;
    }  
    .titreMoins {
        font-size: 18px;
    }
    .titreMoinsMoins {
      font-size: 16px;
    }
    .buttonResi {
      width: 125px;
      height:60px;
    }
    .textbuttonResi {
      font-size: 15px;
    }
    .titreBlocResi{
      font-size: 17px;
    }
    .videBlocResi{
      height: 80px;
    }
    .paragrapheBlocResi{
      font-size: 16px;
    }
    .textOF{
      font-size: 15px;
    }
    .inputResi{
      font-size: 15px;
      height: 20px;
    }
    .buttonPM{
      height: 44px;
    }
    .flexLigneOF{
      height: 50px;
    }
    .TextPM{
      font-size: 25px;
    }
    .card{
      padding-top: 0px;
    }
    .data_tableau_prix_rapport{
      font-size: 15px;
      height: 35px;
    }
    .ordonnée_tableau_prix_rapport{
      font-size: 15px;
      height: 35px;
    }
    .titretableauresi{
      height: 40px;
    }
    .buttonCheckedSimul{
      width: 40%;
    }
    .navbarSW_lien{
      padding: 1.3rem;
    }
    .rep{
      font-size: 16px;
    }
    .buttonEntite{
      height: 40px;
    }
  }
  @media screen and (max-width: 1400px) {
    .buttonCheckedSimul{
      width: 50%;
    }
    .textboxHome{
      width:45%;
    }
    .videoHome{
      width:35%;
    }
    .paragrapheBloc {
      width: 50%;
      font-Size: 20px;
    }
    .titreBloc{
      font-size: 18px;
    }
    
    .dispLeft2050 {
      width: 45%;
    }
    .dispRight2050 {
      width: 55%;
    }
    .abscisse_tab_2050{
      font-size: 14px;
    }
    .menuOad {
      height:55px;
    }
    .SOmenuOad {
      bottom: 55px;
      left: 82%;
    }
    .nomMenuOAD {
      font-size: 14px;
      padding-bottom: 5px;
    }
    .entete {
      height: 40px;
    }
    .encartProfil{
      top: 40px;
    }
    h1{
      font-size: 22px;
    }
    .burgerOAD {
      width: 50px;
      height: 25px;
    }
    .optiLeft{
      width: 35%;
    }
    .optiRight {
      width: 65%;
    }
    .paragrapheAleas {
      font-size: 11px;
    }
    .blocCultureCCult{
      width:50%; 
      margin-left: 20%; 
      margin-top:20px;
    }
    .navbarSW_lien{
      padding: 1rem;
    }
  }
  @media screen and (max-width: 1200px) {
    .paragrapheAlignGauche{
      width: 35%;
    }
    .grapheGaucheCompAssol{
      width: 65%;
    }
    .validationCompAssol{
      width: 80%;
    }
  @media screen and (max-width: 1000px) {
    .pleinBloc{
      width: 60%;
    }
  }
  }
}

@media print {
  .enteteprint{
    display: none;
  }
  .rowresiscale{
    height: 300px;
  }
  .container{
    margin : 0px;
  }
  .landscape {
    width: 100vw;
    height: 100vh;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
    page-break-before: always;
  }
  .PODIUMCA{
    width: 470px; 
    height:325px; 
    margin-top:120px; 
    margin-bottom:50px; 
    margin-left:5%
  }
  .enteteOAD{
    display: none;
  }
  .pageDeGarde {
    display: block;
    width: 1100px;
    height: 1400px;
    page-break-after: always;
  }
  .couv4eme {
    display: block;
    width: 1200px;
    height: 1678px;
    margin: 0px;
  }
  .containerPrint {
    page-break-after: always;
    width: 80%;
    height: 1400px;
    margin: 100px;
  }
  .App-logo{
    width: 30%;
    margin-top: 100px;
    margin-left: 700px;
  }
  .titrePDG_rapport {
    color:#92d050; 
    font-size: 40px;
    font-weight: bold;
    text-align: center;
  }
  .img_rport_PDG{
    width: 35%;
  }
  .page_sommaire_PDG{
    color: #616161;
    font-size: 23px;
  }
  .espaceprint{
    margin-top: 200px;
  }
  .titre_sommaire_PDG {
    color:#92D050; 
    font-size: 23px;
    font-weight: bold;
    margin-left: 10px;
  }
  .row_entete_page_rapport{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-left: 10px;
  }
  .num_page_rapport{
    display: block
  }
  .RiAg_Rapport {
    width: 15%;
    aspect-ratio:7/1;
    margin-left: 5px;
  }
  .DRLong {
    width: 10%;
    aspect-ratio: 4/1;
    margin-right: 5px;
  }
  .AG_rapport{
    justify-content: flex-start;
  }
  .howParagraphe {
    margin-top: 50px;
    width: 80%;
    margin-left: 10%;
  }
  .dispLigneRapport {
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
    width: 80%;
  }
  .titrePlus {
    font-size: 25px;
  }
  .videBlocAssol {
      width: 90%;
      height: 40px;
  }
  .piedRapport{
    display: block;
    position: fixed;
    margin-bottom: 20px;
    bottom: 0;
    left: 10;
  }
  .pointHow {
    font-size: 25px;
  }
  .paragraphe{
    font-size: 20px;
  }
  .paragrapheSmaller{
    font-size: 18px;
  }
  .paragrapheSmaller{
    font-size: 15px;
  }
  .legendGraphiquePrix{
    font-size: 30px;
  }
  .totalCarteRapport{
    width: 300px;
    margin: 0px;
  }
  .lignetableauprint{
    width: 360px;
    margin: 0px;
  }
  .carte_rapport_titre{
    width: 1080px;
  }
  .tableau_prix_rapport{
    margin-top: 20px;
    font-size: 22px;
  }
  .tableau_rendement_rapport{
    font-size: 22px;
  }
  .tableau_podium_rapport{
    font-size: 22px;
  }
  .tableau_carte_rapport{
    width: 100%;
  }
  .contenu_resi_tableau_rapport{
    font-weight: normal;
    height: 30px;
    font-size: 18px;
  }
}

@media screen {
  .piedRapport{
    position: absolute;
    display : none ;
  }
}
